<template>
  <div class="background-content">
    <v-main class="pt-10 ml-1">
      <v-container  fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app> </v-footer>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import NavbarUser from "@/components/NavbarUser.vue";
export default {
  components: {
    Sidebar,
    NavbarUser,
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/main";
.background-content {
  min-height: 100vh;
  background-color: #f6f9ff !important;
}
</style>
